import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import 'survey-core/defaultV2.min.css';
import SplashScreen from './SplashScreen';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import surveyJson from './surveyConfig.json';
import './landingPage.css';

StylesManager.applyTheme("defaultV2");

const IDLE_TIMEOUT = 180000; // 3 minutes in milliseconds

function App() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [showEnterAppPage, setShowEnterAppPage] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const idleTimeoutRef = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSplashScreen(false);
      setShowEnterAppPage(true);
    }, 3000); // Adjust the time as needed

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (showSurvey) {
      const resetIdleTimer = () => {
        if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = setTimeout(() => {
          setShowSurvey(false);
          setShowEnterAppPage(true);
        }, IDLE_TIMEOUT);
      };

      const handleUserActivity = () => {
        resetIdleTimer();
      };

      resetIdleTimer();
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);

      return () => {
        if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
      };
    }
  }, [showSurvey]);

  const survey = new Model(surveyJson);
  const alertResults = useCallback(async (sender) => {
    const results = JSON.stringify(sender.data);
    console.log(results);

    try {
      await saveSurveyResults("https://analytics.theacecards.com/demo.php", sender.data);
    } catch (error) {
      console.error("Error saving survey results:", error);
    }
  }, []);

  survey.onComplete.add(alertResults);

  survey.onCompleting.add(() => {
    survey.completedHtml = "<p style='font-size:24px;'>Your contribution to this system is having a direct and positive impact to everyone that works here<p>";
    const timeoutId = setTimeout(() => {
      survey.clear(true, true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  });

  const handleEnterAppClick = () => {
    setShowEnterAppPage(false);
    setShowSurvey(true);
  };

  return (
    <div className="App">
      {showSplashScreen && <SplashScreen />}
      {showEnterAppPage && (
        <div className="enter-app-page">
          <button className="enter-app-button" onClick={handleEnterAppClick}> Click to fill in a card </button>
        </div>
      )}
      {showSurvey && <Survey model={survey} />}
    </div>
  );
}

async function saveSurveyResults(url, data) {
  try {
    await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log("success");
  } catch (error) {
    console.log("error", error);
  }
}

export default App;
