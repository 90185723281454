import React from 'react';
import './SplashScreen.css';

function SplashScreen() {
  // Customize your splash screen content here
  return (
    <div className="splash-screen">
      <h1>Welcome to Ace Card</h1>
      <img src='https://ik.imagekit.io/dg35kyaml/logo.png?updatedAt=1719340739130' alt='logo' />
      {/* <p>Loading...</p> */}
    </div>
  );
}

export default SplashScreen;
